interface NavigationsState {
       dashboard_side_menu:object[],
}

export const useNavigations = defineStore('navigations', {
    state: (): NavigationsState => {
        return {
            dashboard_side_menu:
                [
                    {
                    title: "Islamic Centers",
                    isAccordianOn: true,
                    subMenuItems: [
                        {
                            title: "My Islamic Center Listings",
                            link: {
                                routeName: "dashboard-islamic-centers",
                                queryParams: {
                                    filter: "active",
                                },
                            },
                            icon: "map:mosque",
                        },
                        {
                            title: "Subscribed Islamic Centers",
                            link: {
                                routeName:
                                    "dashboard-islamic-centers-subscribed-islamic-centers",
                            },
                            icon: "f7:logo-rss",
                        },
                    ],
                },
                    {
                        title: "Businesses",
                        isAccordianOn: true,
                        subMenuItems: [
                            {
                                title: "My Business Listings",
                                link: {
                                    routeName: "dashboard-businesses",
                                    queryParams: {
                                        filter: "active",
                                    },
                                },
                                icon: "ion:business",
                            },
                            {
                                title: "Liked Businesses",
                                link: {
                                    routeName:
                                        "dashboard-businesses-liked-businesses",
                                },
                                icon: "ic:round-loyalty",
                            },
                            {
                                title: "My Business Reviews",
                                link: {
                                    routeName:
                                        "dashboard-businesses-reviews",
                                },
                                icon: "material-symbols:reviews-rounded",
                            },
                            {
                                title: "Availed Coupons",
                                link: {
                                    routeName:
                                        "dashboard-businesses-availed-coupons",
                                    queryParams: {
                                        filter: "active",
                                    },
                                },
                                icon: "ri:coupon-3-fill",
                            },
                        ],
                    },
        {
            title: "Classified",
                isAccordianOn: true,
            subMenuItems: [
            {
                title: "My Classified Ads",
                link: {
                    routeName:
                        "dashboard-classified",
                    queryParams: {
                        filter: "active",
                    },
                },
                icon: "icon-park-solid:view-grid-detail",
            },
            {
                title: "Saved Ads",
                link: {
                    routeName:
                        "dashboard-classified-saved-ads",
                },
                icon: "mdi:newspaper-plus",
            },
        ],
        },
        {
            title: "Fundraising",
                isAccordianOn: true,
            subMenuItems: [
            {
                title: "My Fundraisers",
                link: {
                    routeName:
                        "dashboard-fundraising",
                    queryParams: {
                        filter: "active",
                    },
                },
                icon: "mdi:charity",
            },
            {
                title: "Saved Fundraisers",
                link: {
                    routeName:
                        "dashboard-fundraising-saved-campaigns",
                },
                icon: "material-symbols-light:heart-plus",
            },
            {
                title: "My Donations",
                link: {
                    routeName:
                        "dashboard-fundraising-my-donations",
                },
                icon: "mdi:hand-heart",
            },
        ],
        },
        {
            title: "News",
                isAccordianOn: true,
            subMenuItems: [
            {
                title: "My Announcements",
                link: {
                    routeName:
                        "dashboard-news",
                    queryParams: {
                        filter: "active",
                    },
                },
                icon: "zondicons:announcement",
            },
            {
                title:
                    "Pinned News & Announcements",
                link: {
                    routeName:
                        "dashboard-news-pinned-news",
                },
                icon: "ph:push-pin-fill",
            },
        ],
        },
    ]
        }
    },
});